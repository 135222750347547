<template>
  <CCardBody>
    <!-- Filters -->
    <CCard class="filters" accent-color="warning">
      <CCardHeader
        role="button"
        class="text-warning shadow-none card-header"
        @click="resetFilters"
      >
        <strong class="m-0"><CIcon :name="`cil-filter${$store.state.filterShow ? '-x' : ''}`" /> Filters</strong>
        <div class="card-header-actions">
          <CIcon :name="`cil-chevron-${$store.state.filterShow ? 'bottom' : 'top'}`"/>
        </div>
      </CCardHeader>

      <CCollapse :show="$store.state.filterShow">
        <CCardBody class="p-2">
          <div class="row">
            <!-- Country Filter -->
            <div class="col-lg-3 col-sm-5 mb-2 mb-lg-0">
              <v-select
                class="v-select-filter"
                placeholder="Country.."
                :value="$store.state.filters.country"
                :options="authCountries"
                :reduce="c => c.value"
                :searchable="false"
                @input="countryFilter"
              />
            </div>

            <!-- Search -->
            <div class="col-md mb-1 mb-md-0 pl-md-0">
              <CInput
                class="mb-0 search"
                type="search"
                placeholder="Search for something else ..."
                v-model="search"
                @input="searchFilter"
              />
            </div>
          </div>
        </CCardBody>
      </CCollapse>
    </CCard>

    <CDataTable
      striped
      hover
      :items="loadedItems"
      :fields="fields"
      :sorter="{ external: true, resetable: true }"
      :itemsPerPageSelect="{
        external: true,
        values: [5, 15, 25, 50, 100, 250, 500],
      }"
      :items-per-page.sync="itemsPerPage"
      :loading="loading"
      :noItemsView="{
        noResults: 'No filtering results are available!',
        noItems: 'No restaurants with delivery service found!',
      }"
      @update:sorter-value="sorterValue"
      @pagination-change="paginationChange"
    >
      <!-- Actions -->
      <template #actions="{ item, index }">
        <td>
          <CSpinner v-if="item.deleting" size="sm" color="danger" class="ml-3" />
          <CButton
            v-else
            class="ml-1"
            size="sm"
            color="danger"
            variant="ghost"
            shape="pill"
            @click="deleteItem(item, index)"
          >
            <CIcon name="cil-trash" />
          </CButton>
        </td>
      </template>

      <!-- id -->
      <template #id="{item}">
        <td>
          {{ item.restaurant_id }}
        </td>
      </template>

      <!-- Location -->
      <template #location="{ item }">
        <td>
          <div class="d-flex align-items-center flex-gap-2">
            <CIcon :content="$options.flagSet[item.country.flag]" size="lg" />
            <div>
              <strong>{{item.restaurant.restaurant_city }}</strong> <small>{{item.restaurant.restaurant_postcode }}</small>
            </div>
          </div>
        </td>
      </template>

      <!-- Name -->
      <template #name="{item}">
        <td>
          <strong>
            <CLink :to="{name: 'View Restaurant', params: { id: item.restaurant.id }}" class="text-dark">{{ item.restaurant.restaurant_name }}</CLink>
          </strong>
        </td>
      </template>

      <!-- Commission Type -->
      <template #type_commission_ds="{item}">
        <td class="text-center">
          <strong>
            <CLink :to="{name: 'Edit Restaurant', params: { id: item.restaurant.id }, query: { tab: 7 }}">
              {{ item.restaurant.type_commission_ds }}
            </CLink>
          </strong>
        </td>
      </template>

      <!-- Hubs -->
      <template #hubs="{item}">
        <td class="p-0">
          <CIcon v-if="item._classes === 'table-warning'" name="cil-warning" size="lg" />
          <CBadge class="mx-1" color="secondary" v-for="hub in item.hubs">{{ hub.name }}</CBadge>
        </td>
      </template>

      <!-- Started -->
      <template #started_at="{item}">
        <td>{{ moment(item.started_at).format('LL') }}</td>
      </template>
    </CDataTable>

    <!-- Actions -->
    <CCard class="actions sticky-bottom">
      <CCardBody class="p-2">
        <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
          <div>
            <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5>
          </div>

          <div v-if="pages > 1" class="ml-auto">
            <CPagination
              align="center"
              :dots='false'
              :pages="pages"
              :active-page.sync="activePage"
            />
          </div>
        </div>
      </CCardBody>
    </CCard>

    <mc-spinner :opacity="0.8" v-show="loading" :mtop="(32 + mcSpinnerMarginTop) + 'px'" />
  </CCardBody>
</template>

<script>
import _debounce from 'lodash/debounce';
import { flagSet } from '@coreui/icons'

export default {
  name: "BackendTable",
  flagSet,
  data() {
    return {
      loadedItems: [],
      fields: [
        { key: "actions", label: "", sorter: false, _style: "min-width: 48px; width: 48px;" },
        { key: "id", label: "ID", sorter: false, _style: "min-width: 48px; width: 48px;" },
        { key: "location", label: "Location", sorter: false, _style: "min-width: 180px; width: 180px;" },
        { key: "name", label: "Restaurant Name", _style: "min-width: 150px;" },
        { key: "hubs", label: "Hubs", sorter: false, _style: "min-width: 150px;" },
        { key: "type_commission_ds", label: "Commission", sorter: false, _style: "min-width: 120px; width: 120px; text-align: center; padding-right: 0 !important;" },
        { key: "started_at", label: "Started", _style: "min-width: 140px; width: 140px;" },
      ],

      activePage: 1,
      pages: 1,
      total: 0,
      items_per_page: null,
      orderBy: null,
      direction: null,
      search: null,

      loading: false,

      // Element Cover Spinner
      mcSpinnerMarginTop: 0,
    };
  },

  beforeMount() {
    this.searchFilter = _debounce(() => {
      this.getAllItems();
    }, 1000);
  },

  mounted() {
    this.activePage =  this.queryPage;
    this.getAllItems();

    window.onscroll = () => {
      this.mcSpinnerMarginTop = document.documentElement.scrollTop;
    }
  },

  watch: {
    reloadParams() {
      if(this.queryPage != this.activePage)
        this.$router.push({ name: 'DeliveryService', query: { page: this.activePage } });

      this.onTableChange();
    },
  },

  computed: {
    itemsPerPage: {
      get: function () { return this.items_per_page ? this.items_per_page : parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) },
      set: function (newValue) { this.items_per_page = newValue }
    },
    reloadParams() { return [this.activePage]; },
    queryPage() { return parseInt(this.$route.query.page) || 1; },
  },

  methods: {
    getAllItems() {
      this.loading = true;

      var url = new URL(this.$backend.COURIER.DELIVERY_SERVICE.GET_ALL),
        params = {
          page: this.activePage,
          itemsPerPage: this.itemsPerPage,
          'order-by': this.orderBy,
          direction: this.direction,
          country: this.$store.state.filters.country,
          search: this.search,
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      this.$axios.get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
          if(items.current_page > items.last_page)
            this.activePage = 1;
          else {
            this.activePage = items.current_page;
            this.pages = items.last_page;
            this.total = items.total;

            this.loadedItems = items.data.map((el) => {
              let classes = el.hubs.length ? "" : "table-warning";
              classes += el.restaurant?.none_active == 1 ? " strikeout" : "";

              return {
                ...el,
                country: this.authCountries.find((c) => c.value === el.restaurant?.countryId),
                deleting: false,
                _classes: classes,
              };
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onTableChange() { // Active Page güncellendiğinde çağrılıyor.
      this.getAllItems();
    },
    paginationChange(value) { // Items Per Page güncellendiğinde çağrılıyor.
      this.itemsPerPage = value;
      this.getAllItems();
    },
    sorterValue(item) {
      this.orderBy = item.column;
      this.direction = item.column ? (item.asc ? "asc" : "desc") : null;
      this.getAllItems();
    },
    async deleteItem(item, index) {
      const confirm = await this.mSwal.fire({
        title: "Are you sure you want to delete?",
        text: "The restaurant will no longer have a delivery service!",
        icon: "warning",
      });

      if (confirm.isConfirmed) {
        const deleteUrl = this.$backend.COURIER.DELIVERY_SERVICE.DELETE.replace("{id}", item.id);
        item.deleting = true;

        try {
          const { data } = await this.$axios.delete(deleteUrl);
          index !== -1 && this.$delete(this.loadedItems, index);
          // item._classes = index !== -1 && 'strikeout disable-item';
          item.deleting = false;
          this.$toast.success(data.data.message);
        } catch (error) {
          item.deleting = false;

          if (error.response) {
            this.$toast.error(`${error.response.data.message}`);
          } else if (error.request) {
            this.$toast.error('No response received from the server.');
          } else {
            this.$toast.error('An unexpected error occurred.');
          }
        } finally {
          this.$forceUpdate();
        }
      }
    },

    // Filters
    countryFilter(value) {
      this.$store.commit("setCountryFilter", value);
      this.getAllItems();
    },
    resetFilters() {
      if (this.$store.state.filterShow) {
        this.$store.commit("setCountryFilter", null);
        this.search = "";
        this.getAllItems();
      }
      this.$store.commit('toggle', 'filterShow');
    },
  },
};
</script>

<style>
.table th, .table td {
  padding: 0.5rem 0.25rem;
  vertical-align: middle;
}

.search input {
  font-size: 1rem !important;
}
</style>

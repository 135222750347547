<template>
  <CRow>
    <CCol sm="12">
      <CCard>
        <CCardHeader>
          <CIcon name="cil-truck" />
          <h5 class="d-inline ml-2">Delivery Service</h5>
          <div class="card-header-actions">
            <CButton color="primary" @click="addRestaurantModalShow = true">
              <CIcon name="cil-plus" class="align-bottom" /><strong>Add New</strong> Restaurant
            </CButton>
          </div>
        </CCardHeader>

        <BackendTable ref="backendTable" />
      </CCard>

      <CModal
        class="add-restaurant-modal"
        :title="`Add Delivery Service To Restaurant`"
        color="dark"
        size="lg"
        :show.sync="addRestaurantModalShow"
        :closeOnBackdrop="false"
      >
        <add-restaurant
          :active="addRestaurantModalShow"
          :selected-items.sync="newRestaurants"
        />
        <template #footer class="p-1">
          <CButton @click="addRestaurantModalShow = false" color="secondary">Close</CButton>
          <CButton @click="addRestaurant" color="success" :disabled="!newRestaurants.length">
            <CIcon name="cil-plus" class="mt-0" /> Add
          </CButton>
        </template>
      </CModal>
    </CCol>
  </CRow>
</template>

<script>
import BackendTable from "./BackendTable";
import AddRestaurant from './AddRestaurant'

export default {
  name: "DeliveryService",
  components: {
    BackendTable,
    'add-restaurant': AddRestaurant
  },
  data() {
    return {
      addRestaurantModalShow: false,
      newRestaurants: [],
    }
  },
  methods: {
    async addRestaurant() {
      this.addRestaurantModalShow = false;

      const confirm = await this.mSwal.fire({
        title: "Are you sure you want to continue?",
        text: "Selected restaurants will be added for delivery service!",
        icon: "question",
      });

      if (confirm.isConfirmed) {
        const url = this.$backend.COURIER.DELIVERY_SERVICE.STORE;

        try {
          const { data } = await this.$axios.post(url,
            {
              restaurant_ids: this.newRestaurants.pluck('id').join(','),
            });
          this.$toast.success(`${data.data.message}`);
          this.$refs.backendTable.getAllItems();
        } catch (error) {
          this.$toast.error(typeof this.errors === 'object'
            ? Object.values(this.errors).join()
            : this.errors
          );
        } finally {
          this.newRestaurants = [];
        }
      }
    }
  }
};
</script>

<style>
.add-restaurant-modal .modal-header {
  padding: 0.75rem !important;
}
.add-restaurant-modal .modal-footer {
  padding: 0.25rem !important;
}
</style>

<template>
<div>
  <CInput
    class="mb-3 search"
    type="search"
    placeholder="Search for restaurants..."
    v-model="search"
    @input="searchRestaurants"
  />

  <CSpinner v-if="loading" />

  <CDataTable
    v-else-if="restaurants.length"
    class="table-add-delivery-service"
    striped
    hover
    :items="restaurants"
    :fields="fields"
    :noItemsView="{
      noResults: 'No filtering results are available!',
      noItems: 'No restaurants found!',
    }"
    clickable-rows
    @row-clicked="rowClicked"
  >
    <!-- Check Box -->
    <template #select="{item}">
      <td>
        <CInputCheckbox
          :checked="item._selected"
          @update:checked="() => check(item)"
          custom
        />
      </td>
    </template>

    <!-- ID -->
    <template #id="{item}">
      <td>
        #{{ item?.id }}
      </td>
    </template>

    <!-- Logo -->
    <template #restaurant_logo="{item}">
      <td>
        <CImg
          :src="item.restaurant_logo_path"
          class="c-avatar-img"
          style="width: 36px; height: 36px"
          placeholderColor="lightgray"
        />
      </td>
    </template>

    <!-- Name -->
    <template #restaurant_name="{item}">
      <td>
        {{ item.restaurant_name }} <span v-if="item.none_active">(NONE_ACTIVE!)</span>
      </td>
    </template>

    <!-- Location -->
    <template #location="{item}">
      <td>
        <strong>{{item.restaurant_city }}</strong> <small>{{item.restaurant_postcode }}</small>
      </td>
    </template>
  </CDataTable>
</div>
</template>

<script>
import _debounce from 'lodash/debounce';

export default {
  name: "AddRestaurant",
  props: {
    active: { default: false, type: Boolean },
    selectedItems: { default: () => [], type: Array },
  },
  data() {
    return {
      fields: [
        { key: "select", label: "", sorter: false, _style: "width: 1%" },
        { key: "id", label: "ID", _style: "min-width: 96px; width: 96px;" },
        { key: "restaurant_logo", label: "", sorter: false,  _style: "min-width: 60px; width: 60px;" },
        { key: "restaurant_name", _style: "min-width: 200px;" },
        { key: "location", _style : "min-width: 150px;" },
      ],

      restaurants: [],
      search: null,

      loading: false,
    };
  },

  watch: {
    active: async function (val) {
      if (val) {
        this.search = null;
        this.restaurants = [];
        this.$emit("update:selectedItems", []);
      }
    },
  },

  beforeMount() {
    this.searchRestaurants = _debounce(() => {
      this.restaurants = [];
      if (this.search.length >= 3) {
        this.getRestaurantsWithoutDS();
      }
    }, 1000);
  },

  methods: {
    async getRestaurantsWithoutDS() {
      this.loading = true;
      await this.$axios
        .get(this.$backend.COURIER.DELIVERY_SERVICE.RESTAURANTS_WITHOUT_DS,
          { params: { search: this.search, fields: 'none_active' } })
        .then(({ data }) => {
          this.restaurants = data.map((el) => { return { ...el, _classes: '' } });
        })
        .catch(() => this.restaurants = [])
        .finally(() => this.loading = false)
    },

    rowClicked(item, index, column, e) {
      // INPUT, LABEL ve BUTTON dışında herhangi bir satır nesnesine tıklanırsa;
      if (!['INPUT', 'LABEL', 'BUTTON', 'A'].includes(e.target.tagName)) {
        this.check(item)
      }
    },
    check(item) {
      this.$set(item, "_selected", !item._selected);

      if (item._selected) {
        item._classes = item._classes.concat(" table-selected");
        this.selectedItems.push(item);
      } else {
        item._classes = item._classes.replace(" table-selected", "");
        this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
      }
    },
  },
};
</script>

<style>
.table-add-delivery-service table {
  margin-bottom: 0;
}
.table-add-delivery-service th,
.table-add-delivery-service td {
  padding: 0.5rem 0.5rem;
  vertical-align: middle;
}
.table-add-delivery-service th {
  border-top: none;
}
</style>
